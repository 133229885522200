import './popup.css';

const Popup = ({ message, subMessage, onYes, onNo }) => {
    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <h3>{message}</h3>
                <p>{subMessage}</p>
                <div className="popup-buttons">
                    <button className="btn yes" onClick={onYes}>Yes</button>
                    <button className="btn no" onClick={onNo}>No</button>
                </div>
            </div>
        </div>
    );
};

export default Popup;
