import './home.css';
import axios from 'axios';
import Carousel from "../../components/carousel/carousel";
import Card from '../../components/card/card';
import { useEffect, useState } from 'react';
import Youtube from '../../components/youtube/youtube';
import { RiArrowRightSLine } from "react-icons/ri";
import banner from '../../img/banner.png'
import OtherNews from '../../components/other/oNews';
import Footer from '../../components/footer/footer';
import { HashLink } from 'react-router-hash-link';
import adds1 from '../../img/USEN VOICE TV HIRING DIASPORA.jpg';
import adds2 from '../../img/USEN VOICE TV HIRING.jpg';

const Home = () => {
    const[topHeadlines, setTopHeadlines] = useState([])
    const[topStories, setTopStories] = useState([])


    // Fetch the latest data on component mount
    useEffect(() => {
        axios.get('https://usenapi.onrender.com/blog/latest-news?limit=9')
          .then((response) => {
            const data = response.data
            setTopHeadlines(data)
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });


        axios.get('https://usenapi.onrender.com/blog/latest-news?limit=4')
          .then((response) => {
            const data = response.data
            setTopStories(data)
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
    },[]); 

    return ( 
        <>
            <Carousel />
            <article className="container catchUp">
                <div className="headlines">
                    <div>
                        <span className="usenVoice">UsenVoice TV</span>
                        <p className="heading">Catch up on the Top Headlines</p>
                    </div>
                    <HashLink to='/Topheadline#top'><button className='viewMore'>View more <RiArrowRightSLine className="icon" size={12}/></button></HashLink>                
                </div>

                <section className='headlinesCard'>
                    {
                        topHeadlines.map((news)=>(
                            <Card key={news._id} data={news}/>
                        ))
                    }
                    
                </section>
            </article>

            <article className="videos">
                <div className="container videoCont">
                    <div className="headlines">
                        <div>
                            <span className="usenVoice">UsenVoice TV</span>
                            <p className="heading">Latest Videos on Youtube</p>
                        </div>
                        <HashLink to='https://www.youtube.com/@UsenvoiceTv'><button src>View more {">"}</button></HashLink>                   
                    </div>
                    <Youtube />
                </div>              
            </article>

            <article className='job container'>
                <div className="headlines">
                    <div>
                        <span className="usenVoice">UsenVoice TV</span>
                        <p className="heading">Get A Job With Us</p>
                    </div>
                </div>
                <div className="advert">                    
                    <img src={adds1} alt="adds" />
                    <img src={adds2} alt="adds" />
                </div>
                
            </article>

            <article className="container latest">
                <section className="latestCont">
                    <div className="headlines">
                        <div>
                            <span className="usenVoice">UsenVoice TV</span>
                            <p className="heading">Top Stories</p>
                        </div>
                        <HashLink to='/Topheadline#top'><button className='viewMore'>View more <RiArrowRightSLine className="icon" size={12}/></button> </HashLink>                   
                    </div>
                    <section className='topStoriesCard'>
                        
                    
                        {
                            
                            topStories.map((news)=>(
                                <Card key={news._id} data={news}/>
                            ))
                        }                        
                    </section>
                </section>
                <section className="latestBanner">
                    <img src={banner} alt="banner" />
                    <HashLink to='/About#top'><button>About UsenVoice TV <RiArrowRightSLine className="icon" size={18}/></button></HashLink>
                </section>
            </article>

            <article className="otherNews container">             
                <div className="headlines">
                    <div>
                        <span className="usenVoice">UsenVoice TV</span>
                        <p className="heading">Follow other news</p>
                    </div>
                                    
                </div>
                <OtherNews />
                <HashLink className='shift' to='/Business#top'><button >View more  <RiArrowRightSLine size={12}/></button></HashLink>
                
            </article>
            <Footer />
        
        </> 
    );
}
 
export default Home;<>



</>