import { useNavigate } from 'react-router-dom';
import './card.css';
import './adminCard.css';
import { BsCalendarEvent } from "react-icons/bs";
import axios from 'axios';
import { useState } from 'react';
import Popup from '../popup/popup';
import DOMPurify from 'dompurify';
const Admincard = ({data}) => {
    const [openPopUp, setOpenPopUp] = useState(false)
    const news = data;
    const navigate = useNavigate();

  // Handler to go to the details page
    const goToDetails = (id, category , title) => {
        navigate(`/${category}/${title}`, { state: { id } });
    };
    const openEdit = () => {
        const id = news._id
         navigate('/dashboard/edit', {state:{id}})
    }
    const handleDelete = async () => {
        const id = news._id;
        try{
            const response = await axios.delete(`https://usenapi.onrender.com/blog/news/delete/${id}`, {
                id
            })
            if (response.status === 200) {
                console.log('successfully deleted post');
                window.location.reload();
            }
        }catch(error){
            console.log(error.message);
        }  
    }
    const onNo = () => {
        setOpenPopUp(false)
    }
    return ( 
        <>
            <div className="adminCard card">
                <img src={news.thumb} alt="news" className="cardImg" />
                <div id='cardBody' className="cardBody" onClick={()=>goToDetails(news._id, news.category, news.title)}>
                    <span className="tag usenVoice">{news.category}</span>
                    <div className='cardDate'><BsCalendarEvent size={12} style={{marginRight:`2%`}}/>{news.date}</div>
                    <p className="cardHead">{news.title}</p>
                    <p className='cardSnippet' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(news.body) }}></p>
                </div>
                <div className="ctaBtns">
                    <button onClick={openEdit} className='edit' style={{color: '#06A02F'}}>edit</button>
                    <button onClick={()=>setOpenPopUp(true)} className='delete' style={{color: '#A81C1E'}}>delete</button>
                </div>
                {openPopUp && <Popup message={"Are you sure you want to delete this post?"} subMessage={"This action is irreversable"} onYes={handleDelete} onNo={onNo} /> }
                
            </div> 
        </>
        
    );
}
 
export default Admincard;