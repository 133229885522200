import Breadcrumb from '../../../components/breadcrumb/crumb';
import '../../news/news.css';
import '../editpost/editpost.css';
import './createPost.css';
import banner from '../../../img/adminBanner.png';
import {useNavigate } from 'react-router-dom';
import { useState} from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Adminfooter from '../../../components/footer/adminFooter';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDropzone } from 'react-dropzone';

const CreatePost = () => {
    const navigate = useNavigate();
    const [selectedCat, setSelectedCat] = useState('select category');
    const [bodyvalue, setBodyValue] = useState('');
    const [titlevalue, setTitleValue] = useState('');
    const [thumbnail, setThumbnail] = useState(null);

    // Handle file drop
    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setThumbnail(acceptedFiles[0]);
            toast.success("Thumbnail image selected successfully!");
        }
    };

    // React Dropzone configuration
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/*': [],
        },
        multiple: false,
    });
     // handle category selection
     const selectCategory = (event) => {
        setSelectedCat(event.target.value);
    };

    const handleCreateNews = async () => {
        const formData = new FormData();
        formData.append('title', titlevalue);
        formData.append('body', bodyvalue);
        formData.append('category', selectedCat);
        // Include thumbnail file if selected
        if (thumbnail) {
            formData.append('thumb', thumbnail);
        }

        try {
            const response = await axios.post(`https://usenapi.onrender.com/blog/news/create`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.status === 200) {
                toast.success('News uploaded successfully!', {
                    onClose: () => navigate('/dashboard'),
                });
            }
        } catch (error) {
            console.error('Error updating news:', error);
            toast.error('Failed to update news. Please try again.');
        }
    };

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean'],
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    return (
        <>
            <div className="editPage container">
                <Breadcrumb />
                <ToastContainer />
                <div className="newsBanner">
                    <img src={banner} alt="page banner" className="newsBannerImg" />
                    <div className="newsBannerTxt">
                        <h3 className="bannertitle">UsenVoice TV Admin</h3>
                    </div>
                </div>

                {/* Thumbnail Drag and Drop */}
                <div className="thumbnail">
                    <p className="thumbnailAction">Add Thumbnail Image:</p>
                    <div className="thumbnailImg">
                        <div
                            {...getRootProps({
                                className: 'dropzone',
                            })}
                        >
                            <input {...getInputProps()} />
                            {thumbnail ? (
                                <img
                                    src={URL.createObjectURL(thumbnail)}
                                    alt="Thumbnail Preview"
                                    className="thumbnailPreview"
                                />
                            ) : (
                                <p>Drag & drop a file here, or click to select a thumbnail image</p>
                            )}
                        </div>
                    </div>
                </div>

                <div className="editTitle">
                    <label htmlFor="">News Title</label>
                    <input
                        type="text"
                        value={titlevalue}
                        onChange={(e) => setTitleValue(e.target.value)}
                    />
                </div>

                <div className="editCategory">
                    <label htmlFor="category">News Category</label>
                    <select id="category" name="category" value={selectedCat} onChange={selectCategory}>
                        <option value="All News">select category </option>
                        <option value="News">News</option>
                        <option value="Education">Education</option>
                        <option value="Politics">Politics</option>
                        <option value="Business">Business</option>
                        <option value="National">National</option>
                        <option value="National">Sport</option>
                    </select>
                </div> 

                <div className="editBody">
                    <label htmlFor="">News Body</label>
                    <ReactQuill
                        theme="snow"
                        formats={[
                            'header',
                            'font',
                            'size',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video',
                        ]}
                        modules={modules}
                        value={bodyvalue}
                        onChange={setBodyValue}
                    />
                </div>

                <button onClick={handleCreateNews}>Publish</button>
            </div>
            <Adminfooter />
        </>
    );
};

export default CreatePost;
