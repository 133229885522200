import './maquee.css';

const Marquee = () => {
    return ( 
        <div className="marquee">
            <p className="marqueeTxt">Introducing UsenVoice TV: Elevating the Kingdom of Usen. We are dedicated to showcasing the vibrant culture, history, and spirit of the Kingdom of Usen.</p>
        </div>
    );
}
 
export default Marquee;