import './footer.css';
import footLogo from '../../img/footLogo.png';
import { CiLocationOn, CiPhone } from "react-icons/ci";
import { HashLink } from 'react-router-hash-link';
const Adminfooter = () => {
    return ( 
        <div className="footer adminfooter">
            <div className="footerCont adminFooterCont">
                <div className="footLeft">
                    <div className="footLogo">
                        <img src={footLogo} alt="footer logo" className="fLgog" />
                        <div className="footerContact">
                            <p className="fAddress"> <CiLocationOn className='addressIcon' />4, Mission Road, Usen, Ovia
                            South West LGA, Edo State.</p>
                            <p className="fnumber"><CiPhone />+234 704 300 8330</p>
                        </div>
                    </div>
                    <p className="fAbout">
                        Welcome Usenvoice TV, the newest media company dedicated to showcasing the vibrant culture, history, and spirit of the Kingdom of Usen. Our mission is to provide a dynamic platform that highlights the unique stories, traditions, and achievements of the Usen community, both locally and globally.
                    </p>
                </div>
                <div className="footMiddle">
                    <div className="footHeading">   
                        <p>Site Nav</p>
                        <div></div>
                    </div>
                    <div className="footList">
                        <ul>
                            <HashLink smooth to="/"><li >Home</li></HashLink>
                            <HashLink smooth to="/News"><li >News</li></HashLink>
                            <HashLink smooth to="/About"><li >About UsenVoiceTV</li></HashLink>
                            <HashLink smooth to="/Business"><li >Business</li></HashLink>
                            <HashLink smooth to="/Politics"><li >Politics</li></HashLink>
                        </ul>
                    </div>
                </div>
                <div className="footRight">
                    <div className="footHeading">   
                        <p>Follow us On</p>
                        <div></div>
                    </div>
                    <div className="footList">
                        <ul>
                            <HashLink smooth to="/"><li >Facebook</li></HashLink>
                            <HashLink smooth to="/News"><li >Twitter</li></HashLink>
                            <HashLink smooth to="/About"><li >Instagram</li></HashLink>
                            <HashLink smooth to="/Business"><li >Youtube</li></HashLink>
                            <HashLink smooth to="/Politics"><li >Pintrest</li></HashLink>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <p>Privacy Policy & Terms . Site Credits</p>
                <p>© 2024 All Rights Reserved</p>
            </div>
            
        </div>
    );
}
 
export default Adminfooter;