import './footer.css';
import axios from 'axios';
import footLogo from '../../img/footLogo.png';
import { CiLocationOn, CiPhone } from "react-icons/ci";
import { HashLink } from 'react-router-hash-link';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Footer = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://usenapi.onrender.com/contact/send-email', formData);
            if (response.status === 200) {
                toast.success('Message sent successfully!');
                // To Clear the form fields on submit success
                setFormData({
                    name: '',
                    email: '',
                    message: ''
                });
            }
            
        } catch (error) {
            console.error('Error:', error);
            toast.error('Failed to send message.');
        }
    };

    return ( 
        <div className="footer">
            <ToastContainer />
            <div className="feedBack container">
                <div className="feedbackPack">
                    <p className="fbTitle">Do you have a feedback?</p>
                    <p className="fbSubhead">Kindly get in touch with our team via the form below and we’ll get back to you as soon as we can.</p>
                
                    <form onSubmit={handleSubmit}>
                        <div className="inputs">
                            <input className='name' placeholder='Your Name' type="text" name="name" value={formData.name} onChange={handleChange} required />
                            <input className='email' placeholder='Your E-mail' type="email" name="email" value={formData.email} onChange={handleChange} required />
                        </div>
                        <textarea placeholder="Message" className='Your Message' name="message" value={formData.message} onChange={handleChange} required />
                        <input className='submit' type="submit" value="Send Feedback" />
                    </form>
                </div>
                
            </div>
            <div className="footerCont">
                <div className="footLeft">
                    <div className="footLogo">
                        <img src={footLogo} alt="footer logo" className="fLgog" />
                        <div className="footerContact">
                            <p className="fAddress"> <CiLocationOn className='addressIcon' />4, Mission Road, Usen, Ovia
                            South West LGA, Edo State.</p>
                            <p className="fnumber"><CiPhone />+234 704 300 8330</p>
                        </div>
                    </div>
                    <p className="fAbout">
                        Welcome Usenvoice TV, the newest media company dedicated to showcasing the vibrant culture, history, and spirit of the Kingdom of Usen. Our mission is to provide a dynamic platform that highlights the unique stories, traditions, and achievements of the Usen community, both locally and globally.
                    </p>
                </div>
                <div className="footMiddle">
                    <div className="footHeading">   
                        <p>Site Nav</p>
                        <div></div>
                    </div>
                    <div className="footList">
                        <ul>
                            <HashLink smooth to="/"><li >Home</li></HashLink>
                            <HashLink smooth to="/News"><li >News</li></HashLink>
                            <HashLink smooth to="/About"><li >About UsenVoiceTV</li></HashLink>
                            <HashLink smooth to="/Business"><li >Business</li></HashLink>
                            <HashLink smooth to="/Politics"><li >Politics</li></HashLink>
                        </ul>
                    </div>
                </div>
                <div className="footRight">
                    <div className="footHeading">   
                        <p>Follow us On</p>
                        <div></div>
                    </div>
                    <div className="footList">
                        <ul>
                            <HashLink smooth to="/"><li >Facebook</li></HashLink>
                            <HashLink smooth to="/News"><li >Twitter</li></HashLink>
                            <HashLink smooth to="/About"><li >Instagram</li></HashLink>
                            <HashLink smooth to="/Business"><li >Youtube</li></HashLink>
                            <HashLink smooth to="/Politics"><li >Pintrest</li></HashLink>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="copyright">
                <p>Privacy Policy & Terms . Site Credits</p>
                <p>© 2024 All Rights Reserved</p>
            </div>
            
        </div>
    );
}
 
export default Footer;