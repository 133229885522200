import './crumb.css';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumb = () => {
    const location = useLocation();
    // Split the pathname into an array, e.g., "/shop/product/1" -> ["", "shop", "product", "1"]
    const pathnames = location.pathname.split('/').filter((x) => x);
   

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                {pathnames.map((value, index) => {
                    // Construct the path for each breadcrumb
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    const Value = decodeURIComponent(value);
                   
                    // Render the link or text based on whether it's the last item
                    return index === pathnames.length - 1 ? (
                        <li key={to} className="breadcrumb-item active" aria-current="page">
                            {Value}
                        </li>
                    ) : (
                        <li key={to} className="breadcrumb-item">
                            
                            <Link to={to}>{Value}</Link>
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

export default Breadcrumb;
