import './nav.css';
import { HashLink } from 'react-router-hash-link';
import logo from '../../img/navLogo.png';
import { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="container navbar">
            <nav className="nav">
                <HashLink smooth to="/"><img src={logo} alt="Brand Logo" className="navLogo" /></HashLink>
                <div className="menuToggle" onClick={toggleMenu}>
                    {menuOpen ? <RiCloseLine /> : <RiMenu3Line />}
                </div>
                <div className={`navLinks ${menuOpen ? 'active' : ''}`}>
                    <HashLink smooth to="/"><li className="link">Home</li></HashLink>
                    <HashLink smooth to="/News"><li className="link">News</li></HashLink>
                    <HashLink smooth to="/Business"><li className="link">Business</li></HashLink>
                    <HashLink smooth to="/Politics"><li className="link">Politics</li></HashLink>
                    <HashLink smooth to="/Entertainment"><li className="link">Entertainment</li></HashLink>
                    <HashLink smooth to="/National"><li className="link">National</li></HashLink>
                    <HashLink smooth to="/About"><li className="link">About UsenVoiceTV</li></HashLink>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;
