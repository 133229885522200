// src/components/Loader.js
import React from 'react';
import './loading.css';
import loaderGif from '../../img/loading.gif'; 

const Loader = () => {
  return (
    <div className="loader-container">
        
      <img src={loaderGif} alt="Loading..." className="loader-gif" />
      <p>Loading, please wait...</p>
    </div>
  );
};

export default Loader;
