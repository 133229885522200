import { useNavigate } from 'react-router-dom';
import './card.css';
import { BsCalendarEvent } from "react-icons/bs";
import DOMPurify from 'dompurify';
const Card = ({data}) => {
    const news = data;
    const navigate = useNavigate();

  // Handler to go to the details page
    const goToDetails = (id, category , title) => {
        navigate(`/${category}/${title}`, { state: { id } });
    };
    return ( 
        <>
            <div className="card">
                <img src={news.thumb} alt="news" className="cardImg" />
                <div className="cardBody" onClick={()=>goToDetails(news._id, news.category, news.title)}>
                    <span className="tag usenVoice">{news.category}</span>
                    <div className='cardDate'><BsCalendarEvent size={12} style={{marginRight:`2%`}}/>{news.date}</div>
                    <p className="cardHead">{news.title}</p>
                    <p className='cardSnippet' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(news.body) }} ></p>
                </div>
            </div> 
        </>
        
    );
}
 
export default Card;