import './carousel.css'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
const Carousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [slides, setSlides] = useState([]);
    const navigate = useNavigate();
    // Fetch data on component mount
    useEffect(() => {
        axios.get('https://usenapi.onrender.com/blog/latest-news?limit=3')
          .then((response) => {
            const data = response.data;
            setSlides(data);
           
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
    }, []); // Run only on mount

    // Autoplay function with cleanup to avoid multiple timeouts
    useEffect(() => {
        const timer = setTimeout(() => {
            toNext();
        }, 10000);

        // Clear timeout on unmount or before re-running
        return () => clearTimeout(timer);
    }, [currentIndex]);

    // Logic for next and previous buttons
    const toNext = () => {
        setCurrentIndex(currentIndex === slides.length - 1 ? 0 : currentIndex + 1);
    };
    const toPrevious = () => {
        setCurrentIndex(currentIndex === 0 ? slides.length - 1 : currentIndex - 1);
    };

     // Function to limit words in a paragraph
/*      const limitWords = (text, wordLimit) => {
        return text.split(" ").slice(0, wordLimit).join(" ") + (text.split(" ").length > wordLimit ? "..." : "");
    };
 */    

  // Handler to go to the details page
    const goToDetails = (id, category , title) => {
        navigate(`/${category}/${title}`, { state: { id } });
    };
    return (
        <div className="container blah">
            <div className="overlay"></div>
            <div onClick={toPrevious} className="leftArrow">{"<"}</div>
            <div onClick={toNext} className="rightArrow">{">"}</div>

            {slides.length > 0 && (
                <div>
                    <div 
                        className='sliderBody' 
                        style={{ backgroundImage: `url(${slides[currentIndex]?.thumb})` }}
                    ></div>
                    <div className="carouselTxt">
                        <span className="tag carTag">{slides[currentIndex]?.category}</span>
                        <div className="carPack">
                            <p className="carHead heading">{slides[currentIndex]?.title}</p>
                            <p className="carBody" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(slides[currentIndex]?.body) }}></p>
                        </div>
                        <button className='carouselBtn' onClick={()=>goToDetails(slides[currentIndex]?._id, slides[currentIndex]?.category, slides[currentIndex]?.title)}>Read more {'  >'} </button>
                        
                    </div>
                    <div className='indicatorsPack'>
                        {slides.map((_, slideIndex) => (
                            <div 
                                key={slideIndex}
                                onClick={() => setCurrentIndex(slideIndex)} 
                                className={slideIndex === currentIndex ? "indicators indicator-active" : "indicators"}
                            ></div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Carousel;
