import Breadcrumb from '../../components/breadcrumb/crumb';
import './news.css';
import banner from '../../img/newsBanner.png'
import { useEffect, useState } from 'react';
import axios from 'axios';
import Card from '../../components/card/card';
import { RiArrowRightSLine } from 'react-icons/ri';
import OtherNews from '../../components/other/oNews';
import Footer from '../../components/footer/footer';
import Loader from '../../components/loading/loading';
const Topheadline = () => {
    const [newsData, setNewsData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
/*     const limit = 9; */
    useEffect(() => {
        const fetchData = async (page)  => {
            try{
                const response = await axios.get(`https://usenapi.onrender.com/blog/top-headline`);
                const data = await response.data
                console.log(data)
                setNewsData(data)
                setTotalPages(response.data.totalPages);
            }catch (error) {
                console.error('Error fetching news data:', error);
              } finally {
                setIsLoading(false);
              }

        }
        fetchData(currentPage);
    },[currentPage])

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    return ( 
        <>
            <div className="news container">
                <Breadcrumb />
                <div className="newsBanner">
                    <img src={banner} alt="page banner" className="newsBannerImg" />
                    <div className="newsBannerTxt">
                        <div className='tag'> UsenVoice TV</div>
                        <h3 className="bannertitle">Top Headlines</h3>
                    </div>
                </div>
                {isLoading? 
                    <Loader />:
                    <>
                        <div className='newsItem headlinesCard'>
                            {newsData.map((news)=>(
                                <Card key={news._id} data={news}/>
                            ))} 
                        </div>
                        <div className='pages'>
                            <button className='paginationBtn' onClick={goToPreviousPage} /* disabled={currentPage === 1} */>
                                Prev
                            </button>
                            <span>{currentPage} of {totalPages}</span>
                            <button className='paginationBtn' onClick={goToNextPage} /* disabled={currentPage === totalPages} */>
                                Next
                            </button>
                        </div>
                    </>
                }
                <article className="otherNews container">
                
                    <div className="headlines">
                        <div>
                            <span className="usenVoice">UsenVoice TV</span>
                            <p className="heading">Follow other news</p>
                        </div>
                                           
                    </div>
                    <OtherNews />
                    <button>View more <RiArrowRightSLine  size={12}/></button>
                </article>
                
            </div>
            <Footer />
        </>
    );
}
 
export default Topheadline;