import './about.css';
import banner from '../../img/newsBanner.png';
import Breadcrumb from '../../components/breadcrumb/crumb';
import about from '../../img/navLogo.jpeg';
import man from '../../img/banner.png';
import { useState } from 'react';
import { MdKeyboardArrowDown } from "react-icons/md";
import Footer from '../../components/footer/footer';
const About = () => {

    const [selected, setSelected] = useState(1);

    const data=[
        {
            id:1,
            offer:"Cultural Programming",
            desc:"Discover the rich tapestry of Usen's art, music, dance, and culinary traditions through our innovative documentaries and feature segments."
        },
        {
            id:2,
            offer:"Community News",
            desc:"Stay informed with the latest happenings, events, and initiatives within the Kingdom of Usen. Our news team is dedicated to providing timely and relevant information to our audience."
        },
        {
            id:3,
            offer:"Interviews and Features",
            desc:"Get to know the inspiring individuals and organizations making a difference in the Usen community. Our interviews offer a platform for local leaders, artists, and innovators to share their stories."
        },
        {
            id:4,
            offer:"Educational Content",
            desc:" Access resources and programs that promote literacy, history, and culture within the Kingdom of Usen. We believe in the power of knowledge to uplift and empower our communities."
        },
        {
            id:5,
            offer:"Live Events",
            desc:"Join us for live broadcasts of cultural events, festivals, and community gatherings, bringing the vibrant spirit of Usen directly to your screens."
        }
    ]
    const toggle = (id) => {
        setSelected(id)
    }
    return ( 
        <>
            <div className="container Abouting">
                <Breadcrumb />
                <div className="newsBanner">
                    <img src={banner} alt="page banner" className="newsBannerImg" />
                    <div className="newsBannerTxt">
                        <div className='tag'> UsenVoice TV</div>
                        <h3 className="bannertitle">About UsenVoice TV</h3>
                    </div>
                </div>
                <section className="aboutCont">
                <div className="aboutIntro">
                        <img src={about} alt="logo" />
                        <div>
                            <h4>Introducing Usen TV - Elevating the Kingdom of Usen</h4>
                            <p>Welcome Usenvoice TV, the newest media company dedicated to showcasing the vibrant culture, history, and spirit of the Kingdom of Usen. Our mission is to provide a dynamic platform that highlights the unique stories, traditions, and achievements of the Usen community, both locally and globally.</p>
                        </div>
                </div>
                </section>
                <section className="aboutCont">
                <div className="aboutOffer">
                        <div>
                            <h4 className='aboutHeading'>What we offer...</h4>
                            {
                                data.map((ev )=> (
                                    <div key={ev.id} className="aboutCard">
                                        <h5 onClick={()=>toggle(ev.id)}>{ev.offer} <MdKeyboardArrowDown /></h5>
                                        <p className={selected === ev.id?"cardActive":''} >{ev.desc}</p>
                                    </div>
                                ))
                            }
                            
                        </div>
                        <img src={man} alt="logo" />                    
                </div>
                </section>
                <section className="aboutCont aboutJoin">
                    <h4 className='aboutHeading'>Join Us on This Journey</h4>
                    <span></span>
                    <p>UsenVoice TV is more than just a media outlet; it’s a celebration of who we are as a kingdom. We invite you to join us on this exciting journey as we work to promote the Kingdom of Usen, share our stories, and connect with audiences far and wide.
                        ming launch date and programming schedule! Follow us on our social media platforms and visit our website for the latest updates. Together, let’s amplify the voice of Usen!
                    </p>
                </section>
            </div>
            <Footer />
        </>
        
     );
}
 
export default About;