import Breadcrumb from '../../components/breadcrumb/crumb';
import '../news/news.css';
import './login.css';
import banner from '../../img/adminBanner.png'
import { useState } from 'react';
import axios from 'axios';
import Adminfooter from '../../components/footer/adminFooter';
import { useNavigate } from 'react-router-dom';
const Login = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
       
      e.preventDefault();
      try {
        const response = await axios.post('https://usenapi.onrender.com/admin/user/login', {
          userName,
          password,
        });
  
        // If login is successful, process the response as needed
        if (response.status === 200) {
          console.log('Login successful:', response.data);
          localStorage.setItem('token', response.data);
          navigate('/dashboard')
          // Optionally redirect, save user info, or set authentication state here
        }
      } catch (err) {
        // Handle errors, like incorrect username/password
        console.error('Login failed:', err.response?.data.errors || err.message);
        setError(err.response?.data.errors || 'Login failed. Please try again.');
      }
    };
    return ( 
        <>
            <div className="news container">
                <Breadcrumb />
                <div className="newsBanner">
                    <img src={banner} alt="page banner" className="newsBannerImg" />
                    <div className="newsBannerTxt">
                        <h3 className="bannertitle">UsenVoice TV Admin Login</h3>
                    </div>
                </div>  
                <form className="loginForm" onSubmit={handleLogin}>
                    <div>
                        <label htmlFor="userName">Username</label>
                        <input type="text" id="userName" value={userName} onChange={(e) => setUserName(e.target.value)} required/>
                    </div>
                    <div>
                        <label htmlFor="password">Password</label>
                        <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                    </div>
                    <button className='loginBtn' type="submit">Login</button>
                </form>
                {error && <p className="error-message">{error}</p>}              
            </div>
            <Adminfooter />
        </>
    );
}
 
export default Login;