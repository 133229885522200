import Breadcrumb from '../../../components/breadcrumb/crumb';
import '../../news/news.css';
import './editpost.css';
import banner from '../../../img/adminBanner.png';
import { useLocation , useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Adminfooter from '../../../components/footer/adminFooter';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../components/loading/loading';


const Edit = () => {
    const location = useLocation();
    const { id } = location.state || {};
    const navigate = useNavigate();

    const [news, setNews] = useState(null);
    const [bodyvalue, setBodyValue] = useState('');
    const [titlevalue, setTitleValue] = useState('');
    const [datevalue, setDateValue] = useState('');
    const [thumbnail, setThumbnail] = useState(null);
    const [selectedCat, setSelectedCat] = useState('');

   

    useEffect(() => {
        // Fetch the specific news item by ID
        const fetchNews = async () => {
            try {
                const response = await axios.get(`https://usenapi.onrender.com/blog/news/${id}`);
                setNews(response.data);
                setBodyValue(response.data.body || '');
                setTitleValue(response.data.title || '');
                setDateValue(response.data.date || '');
                setSelectedCat(response.data.category || '')
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        };

        fetchNews();
    }, [id]);

    // Handle file input change
    const handleThumbnailChange = (event) => {
        setThumbnail(event.target.files[0]);
    };
    // handle category selection
    const selectCategory = (event) => {
        setSelectedCat(event.target.value);
    };


    const handleEditNews = async () => {
        const formData = new FormData();
        formData.append('title', titlevalue);
        formData.append('body', bodyvalue);
        formData.append('date', datevalue);
        formData.append('category', selectedCat);
    
        // Include thumbnail file if selected
        if (thumbnail) {
            formData.append('thumb', thumbnail);
        }
    
        try {
            const response = await axios.put(`https://usenapi.onrender.com/blog/news/edit/${id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
        
            if (response.status === 200) {
                toast.success('News updated successfully!', {
                    onClose: () => navigate('/dashboard'),
                });
            }
        } catch (error) {
            console.error('Error updating news:', error);
            toast.error('Failed to update news. Please try again.');
        }
        
    };
    
    if (!news) {
        return <Loader /> ;
    }

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean'],
        ],
        clipboard: {
            matchVisual: false,
        },
    };


    return (
        <>
            <div className="editPage container">
                <Breadcrumb />
                <ToastContainer />
                <div className="newsBanner">
                    <img src={banner} alt="page banner" className="newsBannerImg" />
                    <div className="newsBannerTxt">
                        <h3 className="bannertitle">UsenVoice TV Admin</h3>
                    </div>
                </div>
                <div className="thumbnail">
                    <p className="thumbnailAction">Edit Thumbnail Image:</p>
                    <div className="thumbnailImg">
                        {news.thumb ? (
                            <img src={news.thumb} alt="news thumb" />
                        ) : (
                            <p>No thumbnail image available</p>
                        )}
                        <div className="changeImg">
                            <label htmlFor="thumb">Change Thumbnail Image</label>
                            <input type="file" id="thumb" onChange={handleThumbnailChange} />
                        </div>
                    </div>
                </div>
                <div className="Edits">
                    <div className="editTitle">
                        <label htmlFor="">News Title</label>
                        <input
                            type="text"
                            value={titlevalue}
                            onChange={(e) => setTitleValue(e.target.value)}
                        />
                    </div> 
                    <div className="editCategory">
                        <label htmlFor="category">News Category</label>
                        <select id="category" name="category" value={selectedCat} onChange={selectCategory}>
                            <option value="All News">All News</option>
                            <option value="News">News</option>
                            <option value="Education">Education</option>
                            <option value="Politics">Politics</option>
                            <option value="Business">Business</option>
                            <option value="National">National</option>
                        </select>
                    </div> 
                    <div className="editTitle">
                        <label htmlFor="">News Date</label>
                        <input
                            type="text"
                            value={datevalue}
                            onChange={(e) => setDateValue(e.target.value)}
                        />
                    </div> 
                </div>
                
                <div className="editBody">
                    <label htmlFor="">News Body</label>
                    <ReactQuill
                        theme="snow"
                        formats={[
                            'header',
                            'font',
                            'size',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video',
                        ]}
                        modules={modules}
                        value={bodyvalue}
                        onChange={setBodyValue}
                    />
                </div>
                <button onClick={handleEditNews}>Edit News</button>
            </div>
            <Adminfooter />
        </>
    );
};

export default Edit;
