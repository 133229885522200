import { HashLink } from 'react-router-hash-link';
import './youtube.css';
import { useEffect, useState } from 'react';
import { BsCalendarEvent } from "react-icons/bs";

const Youtube = () => {
  const [videos, setVideos] = useState([]);

  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  };

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch('https://usenapi.onrender.com/blog/videos?maxResults=3');
        const data = await response.json();
        // Ensure 'data.items' exists and is an array before setting state
        if (data && Array.isArray(data.data)) {
          setVideos(data.data); // Adjust based on your backend's response format
        } else {
          console.error("No videos found or invalid response format");
        }
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className="videoGallery">
      {/* Video 1 */}
      <div className="video-card video-card-style-1">
        {videos[0] && (
          <>
            <iframe
              className="video-frame video-frame-1"
              width="100%"
              height="200"
              src={`https://www.youtube.com/embed/${videos[0].snippet.resourceId.videoId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={videos[0].snippet.title}
              loading="lazy"
            ></iframe>

            <div className="videoTxt cardSnippet">
              <HashLink className="videoLink" to={`https://www.youtube.com/watch?v=${videos[0].snippet.resourceId.videoId}`}>
                <h3 className="video-title cardHead">{videos[0].snippet.title}</h3>
                <p>{videos[0].snippet.description}</p>
                <p>
                  <BsCalendarEvent size={12} style={{ marginRight: `2%` }} /> {formatDate(videos[0].snippet.publishedAt)}
                </p>
              </HashLink>
            </div>
          </>
        )}
      </div>
      <div className="videoSide">
          {/* Video 2 */}
        <div className="video-card video-card-style-side">
          {videos[1] && (
            <>
              <iframe
                className="video-frame video-frame-2"
                width="100%"
                height="200"
                src={`https://www.youtube.com/embed/${videos[1].snippet.resourceId.videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={videos[1].snippet.title}
                loading="lazy"
              ></iframe>

              <div className="videoTxt cardSnippet">
                <HashLink className="videoLink" to={`https://www.youtube.com/watch?v=${videos[1].snippet.resourceId.videoId}`}>
                  <h3 className="video-title cardHead">{videos[1].snippet.title}</h3>
                  <p>{videos[1].snippet.description}</p>
                  <p>
                    <BsCalendarEvent size={12} style={{ marginRight: `2%` }} /> {formatDate(videos[1].snippet.publishedAt)}
                  </p>
                </HashLink>
              </div>
            </>
          )}
        </div>

        {/* Video 3 */}
        <div className="video-card video-card-style-side">
          {videos[2] && (
            <>
              <iframe
                className="video-frame video-frame-3"
                width="100%"
                height="200"
                src={`https://www.youtube.com/embed/${videos[2].snippet.resourceId.videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={videos[2].snippet.title}
                loading="lazy"
              ></iframe>

              <div className="videoTxt cardSnippet">
                <HashLink className="videoLink" to={`https://www.youtube.com/watch?v=${videos[2].snippet.resourceId.videoId}`}>
                  <h3 className="video-title cardHead">{videos[2].snippet.title}</h3>
                  <p>{videos[2].snippet.description}</p>
                  <p>
                    <BsCalendarEvent size={12} style={{ marginRight: `2%` }} /> {formatDate(videos[2].snippet.publishedAt)}
                  </p>
                </HashLink>
              </div>
            </>
          )}
        </div>
      </div>
      
    </div>
  );
};

export default Youtube;
